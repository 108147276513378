import React, { useState, useEffect } from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/shared/button";
import { useSelector, useDispatch } from "react-redux";
import { orderBy } from "lodash";
import PageBreadcrumb from "../components/pagebreadcrumb";
import { Auth, API } from "aws-amplify";
import { listUserProfiles } from "../graphql/queries";
import { useForm } from "react-hook-form";
import { createMatch } from "../graphql/mutations";
import { v4 } from "uuid";

const SubmitMatchPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const user = useSelector((state) => state.user.value);
    const [profiles, setProfiles] = useState(
        orderBy(data?.appSync?.listUserProfiles?.items, "duelRating", "desc")
    );
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        if (user?.id) {
            const score1 = parseInt(data.player1Score);
            const score2 = parseInt(data.player2Score);
            if (score1 + score2 !== 5) {
                alert(
                    "The combined score of player one and player two must be 5!"
                );
            } else {
                let input = {
                    id: v4(),
                    playerOneId: user.id,
                    playerTwoId: data.opponent,
                    playerOneScore: score1,
                    playerTwoScore: score2,
                    owner: [user.id, data.opponent],
                };
                API.graphql({
                    query: createMatch,
                    variables: {
                        input: input,
                    },
                })
                    .then((result) => {
                        alert("You have successfully submitted a match!");
                    })
                    .catch((err) => {
                        for (const error of err?.errors) {
                            console.log(error);
                        }
                        alert("There was an error submitting your match!");
                    });
            }
        } else {
            alert("You must be logged in to submit a match!");
        }
    };
    useEffect(() => {
        if (user?.id) {
            API.graphql({
                query: listUserProfiles,
                variables: { limit: 2000 },
            })
                .then((result) => {
                    setProfiles(
                        orderBy(
                            result.data.listUserProfiles.items,
                            "duelRating",
                            "desc"
                        )
                    );
                })
                .catch((err) => console.log(err));
        }
    }, []);
    const columns = [
        {
            name: "Player Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Rating",
            selector: (row) => row.duelRating,
            sortable: true,
        },
    ];

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="Submit a Match" pathname="/submit-match" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Submit a Match"
            />
            <div>
                <div className="container text-center">
                    <div className="mb-4">Please enter your match details. A match must consist of five rounds.</div>
                    <div className="pb-60">
                        {user?.id && (
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                            >
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="opponent"
                                    >
                                        Opponent
                                    </label>
                                    <select
                                        id="opponent"
                                        {...register("opponent", {
                                            required: true,
                                        })}
                                        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                    >
                                        {orderBy(profiles, "name", "asc").map(
                                            (value) => {
                                                return (
                                                    value.id !== user?.id && (
                                                        <option
                                                            value={value.id}
                                                            key={value.id}
                                                        >
                                                            {value.name}
                                                        </option>
                                                    )
                                                );
                                            }
                                        )}
                                    </select>
                                    {errors.opponent && (
                                        <span className="text-red-700">
                                            This field is required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-6">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="player1Score"
                                    >
                                        Your Score
                                    </label>
                                    <input
                                        {...register("player1Score", {
                                            required: true,
                                            max: 5,
                                            min: 0,
                                        })}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        id="player1Score"
                                        type="number"
                                    />
                                    {errors.player1Score && (
                                        <span className="text-red-700">
                                            This field is required, and must be
                                            between 0 and 5
                                        </span>
                                    )}
                                </div>
                                <div className="mb-6">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="player2Score"
                                    >
                                        Opponent Score
                                    </label>
                                    <input
                                        {...register("player2Score", {
                                            required: true,
                                            max: 5,
                                            min: 0,
                                        })}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        id="player2Score"
                                        type="number"
                                    />
                                    {errors.player2Score && (
                                        <span className="text-red-700">
                                            This field is required, and must be
                                            between 0 and 5
                                        </span>
                                    )}
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        onClick={handleSubmit(onSubmit)}
                                        className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                    >
                                        Submit Match
                                    </button>
                                </div>
                            </form>
                        )}
                        {!user?.id && (
                            <h3>You must be logged in to submit a match!</h3>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

SubmitMatchPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query SubmitMatchPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        appSync {
            listUserProfiles(limit: 2000) {
                items {
                    id
                    name
                    duelRating
                    avatar
                }
            }
        }
    }
`;
export default SubmitMatchPage;
